@import "../../../../commonStyles/main.module.scss";

.modalTitle {
  @include flex($ali: center);
  @include font_h2;
  gap: 10px;
  margin-bottom: 22px;

  button {
    padding: 0 5px;

    svg {
      margin: 0;
    }
  }
}

.contentContainer {
  @include flex($dir: column);
  gap: 20px;

  input {
    margin-bottom: 0 !important;
  }

  button {
    background-color: $mini-button-color;
    justify-content: center;
  }
}
