// texts
$text-color: rgb(255, 255, 255);
$text-color-description: #676a81;
$text-color-gray: rgba(255, 255, 255, 0.6);
$white: #ffffff;
$blue-color: #006bff;
$green-color: #81cf1d;
$red-color: #fe4545;
$dark-red-color: #d94848;
$main-background: #10101a;
$black-select-background: #1A1C2B;
//label
$red-text: rgb(207, 72, 29);
$red-background: rgba(207, 157, 29, 0.2);
$green-text: $green-color;
$green-background: rgba(129, 207, 29, 0.2);
$black-background: #1b1e2f;
// cards
$token-card-bg: #171a2b;
$card-background: #1f2234;
$calendar-background: #3a3e53;
$mini-card-background: #24273a;
$resources-card-background: #222538;
$resources-add-background: #2b2e42;
$card-row-background: rgba(42, 46, 70, 0.5);
// buttons
$button-color: rgb(58, 62, 86);
$mini-button-color: #2d3045;
$chart-setting-button: #2a2e46;
// icon
$icon-color: #9b9ca6;
// charts
$chart-background: #232538;
// diagram
$color-1: #fe7050;
$color-2: #782fd4;
$color-3: #6ce4dd;
$color-4: #f1ff87;
$color-5: #feb950;
$color-6: #24f1008f;
// modal
$modal-shadow: rgba(0, 0, 0, 0.5);
$modal-menu: rgba(5, 7, 17, 1);
$modal-content: rgb(31, 34, 52);

$error-color: #ff0000;
$focus-color: #02afff;
$disabled-buttons: #3a3e56;
