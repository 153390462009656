@import "../../../../../commonStyles/mixins.module";

.item {
  @include flex($just: space-between);
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 10px 20px;
  background-color: $mini-card-background;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: $blue-color;
  }

  & .pair {
    @include mainFont($size: 14px, $lh: 16px);
  }

  & .price {
    @include mainFont(
      $size: 14px,
      $lh: 16px,
      $color: $text-color-description
    );
    margin-right: 20px;
    margin-left: auto;
  }
}
