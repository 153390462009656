@import "../../../../commonStyles/mixins.module";

.ItemContainer {
  @include flex($ali: center, $just: space-between);
  padding: 13px 10px;
  border-radius: 5px;

  &:nth-child(odd) {
    background-color: $resources-card-background;
  }

  &:last-child {
    margin-bottom: 0;
  }

  & .imgWrapper{
    @include imageWrapper;
    margin-right: 10px;
  }

  & .text {
    @include flex($dir: column, $just: space-between, $ali: flex-start);
    flex-grow: 1;
    height: 36px;

    & .time {
      @include mainFont($size: 10px, $lh: 12px);
      color: $text-color-description;
    }

    .type {
      @include mainFont($size: 12px, $lh: 14px, $fw: 400);
      margin-left: 5px;
      color: $blue-color;
    }

    .sell {
      color: $red-color;
    }

    .buy {
      color: $green-color;
    }
  }
}
