@import "../../../commonStyles/main.module.scss";

.DashBoardPageContainer {
  @include mainContainer;
  display: grid;
  grid-template-columns:
    calc((100% - 40px) * 0.3)
    calc((100% - 40px) * 0.4)
    calc((100% - 40px) * 0.3);
  grid-template-areas: "balance cards history";
  gap: 20px;
  padding-bottom: $big-desktop-padding;

  @include big-desktop {
    padding-bottom: $tablet-padding;
  }

  @include desktop {
    margin-top: 100px;
    padding-bottom: 100px;
  }

  @include large {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "cards cards" "balance history";
    grid-template-rows: 200px;
    grid-gap: 15px;
  }

  @include tabletSmall {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas: "balance" "cards" "history";
  }

  & .column {
    @include flex($dir: column);
    gap: 20px;
    box-sizing: border-box;

    @include large {
      gap: 15px;
    }

    & .cardsWrapper {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));
      grid-auto-rows: minmax(150px, max-content);
      gap: 20px;
      height: fit-content;

      @include large {
        grid-template-columns: 170px;
        grid-auto-flow: column;
        grid-auto-columns: 170px;
        gap: 15px;
      }

      @include tabletSmall {
        grid-template-columns: repeat(2, calc(50% - 7.5px));
        grid-auto-flow: row;
        grid-auto-rows: auto;
      }
    }
  }

  & .column:nth-child(1) {
    grid-area: balance;

    @include tabletMedium {
      height: auto;
    }
  }

  & .column:nth-child(2) {
    grid-area: cards;

    @include large {
      overflow-x: auto;
    }

    &::-webkit-scrollbar {
      @include large {
        display: none;
        width: 0;
      }
    }
  }

  & .column:nth-child(3) {
    grid-area: history;
  }
}
