@import "../../../commonStyles/main.module";

button {
  position: relative;
}

.primary {
  @include btn;

  &.active {
    background-color: $blue-color;
    box-shadow: $main-shadow;

    svg {
      stroke: $white;
    }
  }
}

.bigModal {
  @include btn;
  width: 100%;
  height: 48px;
  margin-top: 10px;
  justify-content: center;
  background-color: $mini-card-background;

  &.active {
    background-color: $blue-color;
    box-shadow: $main-shadow;
  }
}

.transparent {
  @include btn;
  background-color: transparent;
  border: none;
  box-shadow: none;
  min-width: max-content;

  &:hover {
    box-shadow: none;
  }

  &:hover:not(:disabled) {
    box-shadow: none;
  }
}

.helperWithSvg {
  @include btn;
  background-color: rgba(42, 46, 70, 0.5);;
  border: none;
  box-shadow: none;
  width: 100%;
  justify-content: center;
  font-size: 13px;

  &:hover {
    box-shadow: none;
  }

  &:hover:not(:disabled) {
    box-shadow: none;
  }
}

.link {
  @include flex($ali: center);
  padding: 10px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  @include mainFont($size: 13px, $lh: 16px);
  border-radius: 5px;
  text-decoration: none;
    @include desktop{
      position: relative;
      padding-top: 25px;
    }

  &.active {
    background-color: $blue-color;
    box-shadow: $main-shadow;
    pointer-events: none;
      @include desktop{
        background-color: transparent;
        box-shadow: none;
        color: $blue-color;
      }

    svg {
      stroke: $white;
        @include desktop{
          stroke: $blue-color;
        }
    }
  }

  svg {
    margin-right: 8px;
    stroke: $icon-color;
      @include desktop{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-right: 0;
      }

      @include mobile{
        position: absolute;
        top: 0;
        margin-right: 0;
      }
  }

  @include mobile {
    justify-content: center;
  }
}

.withoutBorder {
  @include flex($ali: center);
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  @include mainFont($size: 13px, $lh: 16px);
  border-radius: 5px;
  text-decoration: none;

  svg {
    margin-right: 8px;
  }
}

.setting {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.comingSoon {
    @include mainFont($size: 8px, $lh: 16px);
    position: absolute;
    width: 30px;
    top: 13px;
    right: -24px;
    background-color: $blue-color;
    border-radius: 20px 8px 20px 8px;

    @include desktop {
      top: 0px;
      right: -6px;
    }

    @include mobile {
      top: 0px;
      right: -15px;
      width: 24px;
      font-size: 6px;
    }
}

.soonButton {
  width: fit-content;
}
