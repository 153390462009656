
@font-face {
  font-family: "OpenSans";
  src: url("../assets/fonts/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "OpenSans";
  src: url("../assets/fonts/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "OpenSans";
  src: url("../assets/fonts/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "OpenSans";
  src: url("../assets/fonts/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "OpenSans";
  src: url("../assets/fonts/OpenSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}
