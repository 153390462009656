@import "colors.module";

// device width
$small: 320px;
$medium: 393px;
$mobile: 425px;
$tablet-small: 640px;
$tablet-medium: 768px;
$tablet-big: 830px;
$large: 1024px;
$desktop: 1320px;
$big-desktop: 1440px;
// font size and weight
$h1: 32px;
$h2: 20px;
$h3: 16px;
$h4: 22px;
$h5: 20px;
$h6: 18px;
$p: 16px;
$css_content: 15px;
$small: 14px;
// font weight
$main-font-weight: 600;
// font-family
$main-family: OpenSans, "sans-serif";
//height
$btn-height: 30px;
$header-height: 70px;
$breadcrumbs-height: 40px;
// width
$btn-width: 150px;
$btn-width-wide: 200px;
//border
$b-rad-small: 2px;
$b-rad-middle: 8px;
$b-rad-desktop: 24px;
//z-index
$zIndex-header: 200;
$zIndex-popup: 300;
$zIndex-err-popup: 400;
// margins & paddings
$main-margin: 30px;
$main-padding: 30px;
$content-padding: 20px;
$big-desktop-padding: 20px;
$tablet-padding: 15px;
// shadow
$main-shadow: 0px 2px 7px rgba(0, 107, 255, 0.2),
  0px 2px 20px rgba(0, 107, 255, 0.3);
// Z-index
$default-z-index: 0;
$external-el-z-index: 1;
$fixed-el-z-index: 2;
$modal-z-index: 3;
$upper-modal-z-index: 4;
$all-overlay-z-index: 5;
// chart
$chart-main: #81cf1d;
$chart-add: #006bff;
//blur
$overlay-blur: 12px;

// portfolio areas
$balance: portfolioBalance;
$diagram: portfolioDiagram;
$change: portfolioChange;
$assets: portfolioAssets;
$exchange: portfolioExchange;
$history: portfolioHistory;
$tradeHist: tradeHistory;
