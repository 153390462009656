@import "src/commonStyles/main.module.scss";

.header {
  box-sizing: border-box;
  @include flex;
  position: fixed;
  top: 0;
  width: calc(96% - 260px);
  max-width: 1190px;
  margin-left: 220px;
  padding-top: $big-desktop-padding;
  padding-bottom: 10px;
  padding-left: $big-desktop-padding;
  background-color: $main-background;
  z-index: $upper-modal-z-index;

  @include big-desktop {
    width: calc(100vw - 260px);
    padding-top: $tablet-padding;
    padding-bottom: $tablet-padding;
    padding-left: $tablet-padding;
  }

  @include desktop {
    left: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 0;
    background-color: $card-background;
  }

  .logo {
    @include mainFont($size: 24px, $lh: 24px);
    display: none;
    width: fit-content;
    font-weight: 900;
    gap: 5px;
    margin: 0 auto;
    padding-bottom: 10px;

    cursor: pointer;

    label {
      cursor: pointer;
    }

    span {
      color: $blue-color;
      cursor: pointer;
    }

    @include desktop {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include mobile {
      font-size: 18px;
    }
   }

   .support {
    position: absolute;
    display: none;

    @include desktop {
      display: block;
      right: 35px;
    }

    @include mobile {
      right: 14px;
    }

    section {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }

    :global(.language) {
      &__control {
        border-radius: 5px;
        min-height: 30px;
        height: unset;
        margin-bottom: 5px;
      }

      &__value-container,
      &__input-container {
        padding: 0;
        width: 30px;
        padding-left: 5px;
        @include mainFont($size: 13px, $lh: 16px);

        @include mobile {
          font-size: 10px;
          width: 25px;
        }
      }

      &__indicator {
        padding: 0;
        padding-right: 5px
      }

      &__menu {
        &-list {
          @include customScrollbar;
          @include mainFont($size: 13px, $lh: 16px);
        }

        margin-top: 5px;
        z-index: $all-overlay-z-index
      }
    }

    button {
      padding: 0;
      height: 30px;
      width: 35px;

      svg {
        margin: 0;
      }
    }
  }
}
