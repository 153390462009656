@import "../../../../commonStyles/mixins.module";

.ChartSettingContainer {
  @include flex($just: space-between);
  width: 100%;
  margin-top: 20px;

  button {
    flex: 1 0;
    padding: 10px;
    @include mainFont($size: 12px, $lh: 16px);
    background-color: $chart-setting-button;
    border-radius: 5px;
    border: none;
    cursor: pointer;

    &.small {
      padding: 7px;
    }
  }

  button:not(:last-child) {
    margin-right: 5px;
  }

  button.active {
    background-color: $blue-color;
    pointer-events: none;
  }
}
