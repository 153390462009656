@import "../../../../commonStyles/main.module.scss";

.ModalContent {
  @include tabletMedium {
    max-height: calc(100vh - 100px);
    overflow: auto;
    margin-top: 10px;
  }

  .title {
    @include mainFont($size: 20px, $lh: 20px);
    margin-bottom: 20px;

    @include tabletMedium {
      max-height: calc(100vh - 100px);
      font-size: 16px;
    }
  }

  .pairs {
    @include flex($dir: column);
    width: 100%;
    height: 325px;
    margin-top: 10px;
    overflow-x: auto;

    .loupe {
      text-align: center;
      margin-top: 15%;

      svg {
        width: 100px;
        path {
          fill: rgb(73, 77, 103);
        }
      }
    }

    .label {
      @include mainFont($size: 18px, $lh: 24px);
      width: 170px;
      margin: 20px auto;
    }
  }
}
