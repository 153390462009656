@import "../../../../commonStyles/main.module.scss";

.modalTitle {
  @include flex($ali: center);
  @include font_h2;
  gap: 10px;
  margin-bottom: 22px;

  button {
    padding: 0 5px;

    svg {
      margin: 0;
    }
  }
}

.contentContainer {
  @include flex($dir: column);
  gap: 20px;

  input {
    margin-bottom: 0 !important;
  }

  button {
    background-color: $mini-button-color;
    justify-content: center;
  }

  .register {
    @include flex($dir: column, $ali: center);
    @include mainFont($size: 13px, $lh: 16px, $color: $text-color-description);

    a {
      color: $blue-color;
      text-decoration: none;
    }
  }
}

.loupe {
  text-align: center;
  margin-top: 15%;

  svg {
    width: 100px;
    path {
      fill: rgb(73, 77, 103);
    }
  }

  .label {
    @include mainFont($size: 18px, $lh: 24px);
    width: 250px;
    margin: 20px auto;
  }
}
