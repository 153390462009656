@import "../../../commonStyles/mixins.module";

.LoginPageContainer {
  max-width: 1400px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;

  .bgVideo {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right top;
    z-index: -1;
    filter: hue-rotate(200deg) opacity(0.1);
  }

  & .LoginPageContent {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 20px;
    background-color: $card-background;
    border-radius: 10px;

    @include mobile {
      width: 85%;
    }

    .ressetPasswordLogo {
      @include mainFont($size: 20px);
      text-align: center;
      margin-bottom: 10px;
    }

    .forgotInstruction {
      @include mainFont($size: 14px);
      text-align: center;
      margin-bottom: 20px;
    }

    .forgotPassword {
      @include mainFont($size: 10px);
      position: absolute;
      color: $blue-color;
      right: 20px;
      cursor: pointer;
    }

    .errorLabel {
      @include mainFont($size: 14px);
      color: $red-text;
    }
  }

    & .title {
    margin: 0 0 20px 0;
    @include mainFont($size: 20px, $lh: 20px);
  }

  .LoginPageButtons {
    display: flex;

    button {
      margin: 10px 10px 0 0;
    }
  }
}
