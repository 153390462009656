@import "../../../commonStyles/main.module.scss";

.CustomInputContainer{
  @include flex($just: space-between);
  margin-bottom: 10px;
  padding: 14px 10px;
  background-color: $mini-card-background;
  border-radius: 5px;
  cursor: text;

  & label{
    cursor: text;
    @include mainFont($size: 13px, $lh: 16px);
  }

  & input{
    @include mainFont($size: 13px, $lh: 16px, $color: $text-color-description);
    width: 40%;
    border: none;
    background-color: $mini-card-background;
    text-align: right;

    &:focus,
    &:active{
      outline: none;
    }

    &::placeholder{
      @include mainFont($size: 13px, $lh: 16px, $color: $text-color-description);
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
