@import "../../../commonStyles/mixins.module";

.DynamicsContainer {
  @include cardContainer;
  position: relative;
  min-height: 320px;

  .title {
    @include flex($just: space-between, $ali: flex-start);
    gap: 10px;
  }

  .description {
    position: relative;
    @include flex($dir: column);
    width: 200px;
    padding: 5px 5px;
    background: $chart-background;
    border-radius: 5px;
    box-sizing: border-box;
    z-index: 1;

    .select {
      z-index: 1;
    }

    .value {
      @include mainFont($size: 12px, $lh: 14px);
      white-space: nowrap;
    }

    :global(.currency) {
      &__control {
        border-radius: 5px;
        min-height: unset;
        height: unset;
        margin-bottom: 5px;

        &--is-focused {
          outline-style: solid !important;
          outline-width: 1px !important;
          outline-offset: 4px;
          outline-color: #2684ff !important;
          box-shadow: none;
        }
      }

      &__control--menu-is-open {
        :global(.currency) {
          &__single-value {
            display: none;
          }
        }

      }

      &__value-container,
      &__input-container {
        padding: 0;
        @include mainFont($size: 16px, $lh: 16px);
      }

      &__indicator {
        padding: 0;
      }

      &__menu {
        &-list {
          @include customScrollbar;
        }

        margin-top: 5px;
        z-index: 2;
      }
    }

    .info {
      width: 100%;
      @include flex;
    }
  }

  .cardTitle {
    @include font_h2;
    flex-shrink: 1;
  }

  .chart {
    position: relative;
    margin-top: 20px;
    z-index: 0;
  }
}
