@import "../../../commonStyles/main.module";

.rdrCalendarWrapper,
.rdrMonthAndYearWrapper,
.rdrDateDisplayWrapper {
  color: $white;
  background: $calendar-background;
  border-radius: 5px;
}

.rdrDateDisplayItem,
.rdrNextPrevButton {
  background: $chart-setting-button;

  input {
    color: $white;
  }
}

.rdrMonthPicker,
.rdrYearPicker {
  background: $chart-setting-button;
  border-radius: 5px;

  select {
    &::-webkit-scrollbar {
      width: 11px;
      background-color: transparent;
      cursor: all-scroll !important;
    }

    &::-webkit-scrollbar-track {
      background-color: $chart-setting-button;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $text-color-description;
      border-radius: 1px;
      background-clip: content-box;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      cursor: all-scroll !important;
    }
  }

  select,
  option {
    color: $white;
    background: $chart-setting-button;
    text-align: start;
  }
}

.rdrDayNumber span {
  color: $white;
  font-weight: 900;
}

.rdrDayPassive span {
  font-weight: 100;
  display: none;
}

.rdrCalendarWrapper,
.rdrDefinedRangesWrapper {
  @include tabletMedium {
    width: 100%;
    font-size: 10.5px;
    padding: 4px;
  }
}

.rdrDefinedRangesWrapper {
  display: none;
}
