@import "../../../commonStyles/main.module";

.inputContainer {
  position: relative;
  @include flex($dir: column);
  width: 100%;

  .inputLabel {
    @include mainFont($size: 13px, $lh: 16px, $color: $text-color-description);
    user-select: none;

    &:after {
      content: ":";
    }
  }

  .requiredField:before {
    content: "*";
    color: $red-text;
    padding-right: 2px;
  }

  .input {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 15px 10px 15px;
    @include mainFont($size: 13px, $lh: 16px);
    background: rgba(42, 46, 70, 0.5);
    border: none;
    border-radius: 5px;

    &::placeholder {
      color: $white;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  .showPassword {
    cursor: pointer;
    & svg{
      bottom: 15px;
      right: 5px;
    }
  }

  & svg {
    position: absolute;
    top: auto;
    bottom: 20px;
    left: auto;
    right: 10px;
  }

  .clearValue {
    svg {
      position: absolute;
      top: auto;
      bottom: 19px;
      left: auto;
      right: 30px;
      width: 18px;
      height: 18px;
      cursor: pointer;

      @include tabletMedium {
        bottom: 9px;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: $text-color;
  -webkit-box-shadow: 0 0 0px 50px #25283d inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: $text-color !important;
}
