@import "../../../../commonStyles/main.module.scss";

.modalTitle {
  @include font_h2;
}

.confirmContainer {
  @include flex($dir: column, $ali: center);
  width: 100%;
  gap: 20px;
  @include mainFont($size: 13px, $lh: 16px, $color: $text-color-description);

  .text {
    max-width: 70%;
    text-align: center;
  }

  button {
    width: 100%;
    @include flex($ali: center, $just: center);
    height: 54px;
    background-color: $mini-button-color;
    flex: 1 0 calc(50% - 5px);
  }

  .buttonsContainer {
    @include flex;
    gap: 10px;
    width: 100%;
  }
}
