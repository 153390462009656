@import "../../../../commonStyles/mixins.module";

.CurrencyCardContainer {
  @include cardContainer;
  @include flex($dir: column);
  gap: 10px;
  box-sizing: border-box;
  border: 1px solid $main-background;
  height: 200px;

  &.drag {
    opacity: 0.5;

    & * {
      pointer-events: none;
    }
  }

  .settingsModal * {
    pointer-events: all;
  }

  .draggableButton * {
    cursor: move;
    svg {
      width: 20px;
    }
  }

  & .top {
    @include flex($just: space-between);

    & .title {
      @include font_h3;
      font-size: 14px;
      margin-bottom: 0;

      @include tabletMedium {
        font-size: 12px;
        width: 80px;
        word-break: break-word;
      }
    }

    .buttons {
      @include flex($just: flex-start, $ali: center);
      gap: 10px;

      & > button svg {
        width: 20px;
        height: 20px;
      }

      & > button:hover path {
        stroke: $white;
      }
    }
  }

  & .cardDescription {
    @include flex;

    & .coast {
      @include mainFont($size: 12px, $lh: 14px);
    }
  }

  & .daySelector {
    @include flex($just: space-between);

    @include big-desktop {
      display: none;
    }

    & .xAxisTick {
      text-transform: capitalize;
      padding: 1px 4px;
      @include mainFont($size: 11px, $lh: 16px);
      background-color: $mini-button-color;
      border: none;
      border-radius: 5px;
    }
  }

  & .bottom {
    @include flex;

    @include big-desktop {
      margin-top: 10px;
    }

    & .imageWrapper {
      @include imageWrapper;

      svg,
      path {
        fill: white;
        width: 20px;
      }
    }

    & .description {
      flex: 1 0 auto;
      @include flex($dir: column, $just: space-between);
      padding-top: 3px;
      padding-bottom: 3px;

      & .value1 {
        @include mainFont($size: 14px, $lh: 16px);
      }

      & .value2 {
        @include mainFont(
          $size: 10px,
          $lh: 14px,
          $color: $text-color-description
        );
      }
    }
  }
}

.CurrencyCardContainerHovered {
  border: 1px solid $blue-color;

  & button:hover path {
    stroke: $white;
  }
}
