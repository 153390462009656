@import "../../../commonStyles/main.module.scss";

.CardOverlay {
  @include flex($ali: center, $just: center);
  flex-direction: column;
  gap: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: $text-color-description;
  backdrop-filter: blur(4px);
  background-color: #1f2234b8;
  border-radius: 10px;
  z-index: $modal-z-index;

  .CardOverlayLogo {
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;

    .cardTitle {
      @include font_h2;
      flex-grow: 1;

      @include large {
        font-size: 16px;
      }
    }

    @include big-desktop {
      padding: 15px;
    }
  }

  button {
    box-shadow: $main-shadow;
  }
}
