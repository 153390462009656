@import "../../../commonStyles/main.module.scss";

.PageControllerContainer {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 220px 1fr;
  grid-template-rows: auto 1fr;
  grid-auto-columns: auto 1fr;
  grid-auto-flow: row;
  grid-template-areas:
    "asideContainer header"
    "asideContainer content";
  column-gap: 20px;
  width: 96%;
  max-width: 1450px;
  height: 100vh;
  margin: 0 auto;
  padding: 20px;

  @include big-desktop {
    column-gap: 15px;
  }

  @include desktop {
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    grid-template-areas:
      "header"
      "content";
  }

  @include mobile {
    padding: 20px 5px;
  }
}
