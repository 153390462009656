@import "../../../commonStyles/main.module.scss";

.SelectContainer {
  position: relative;

  & .label {
    position: absolute;
    top: calc((100% - 10px) / 2);
    left: 10px;
    z-index: $external-el-z-index;
    @include mainFont($size: 13px, $lh: 16px);
    transform: translateY(-50%);
    pointer-events: none;
  }

  & .value {
    position: absolute;
    top: 50%;
    left: auto;
    right: 100px;
    @include mainFont($size: 13px, $lh: 16px, $color: $text-color-description);
    z-index: external-el-z-index;
    transform: translateY(-50%);
  }
}
