@import "./constants.module";

// fonts
@mixin _fontProps($size, $weight, $color) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin mainFont($size, $lh: inherit, $color: $text-color, $fw: $main-font-weight) {
  font-family: $main-family;
  font-weight: $fw;
  font-size: $size;
  line-height: $lh;
  color: $color;
  font-style: normal;
  user-select: none;
}

@mixin font_h1($size: $h1, $weight: 700, $color: $text-color) {
  @include _fontProps($size, $weight, $color);
}

@mixin font_h2($size: $h2, $weight: $main-font-weight, $color: $text-color) {
  @include _fontProps($size, $weight, $color);
  line-height: $h2;
  font-family: $main-family;
}

@mixin font_h3($size: $h3, $weight: $main-font-weight, $color: $text-color) {
  @include _fontProps($size, $weight, $color);
  line-height: $h3;
  font-family: $main-family;
}

@mixin font_h4($size: $h4, $weight: 400, $color: inherit) {
  @include _fontProps($size, $weight, $color);
}

@mixin font_h5($size: $h5, $weight: 400, $color: inherit) {
  @include _fontProps($size, $weight, $color);
}

@mixin font_h6($size: $h6, $weight: 400, $color: inherit) {
  @include _fontProps($size, $weight, $color);
}

@mixin font_p_light($size: $p, $weight: 400, $color: #fff) {
  @include _fontProps($size, $weight, $color);
}

@mixin font_p_dark($size: $p, $weight: 400, $color: $text-color) {
  @include _fontProps($size, $weight, $color);
}

// device size
@mixin small() {
  @media only screen and (max-width: #{$small}) {
    @content;
  }
}

@mixin mediumMobile() {
  @media only screen and (max-width: #{$medium}) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: #{$mobile}) {
    @content;
  }
}

@mixin tabletSmall {
  @media only screen and (max-width: #{$tablet-small}) {
    @content;
  }
}

@mixin tabletMedium {
  @media only screen and (max-width: #{$tablet-medium}) {
    @content;
  }
}

@mixin tabletBig {
  @media only screen and (max-width: #{$tablet-big}) {
    @content;
  }
}

@mixin large {
  @media only screen and (max-width: #{$large}) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: #{$desktop}) {
    @content;
  }
}

@mixin big-desktop {
  @media only screen and (max-width: #{$big-desktop}) {
    @content;
  }
}

// flex box
@mixin flex(
  $disp: flex,
  $dir: row,
  $wrap: nowrap,
  $just: inherit,
  $ali: inherit
) {
  display: $disp;
  flex-direction: $dir;
  flex-wrap: $wrap;
  justify-content: $just;
  align-items: $ali;
}

@mixin absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin hideScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin customScrollbar {
  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
    cursor: all-scroll !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $text-color-description;
    border-radius: 1px;
    background-clip: content-box;
    border-left: 8px solid transparent;
    cursor: all-scroll !important;
  }
}

// button
@mixin btn {
  @include flex($just: left, $ali: center);
  width: auto;
  padding: 10px 18px;
  background-color: $button-color;
  border: none;
  cursor: pointer;
  @include mainFont($size: 12px, $lh: 20px);
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.15s linear;

  &.active {
    background-color: $blue-color;
  }

  & svg {
    margin-right: 8px;
    stroke: $text-color;
  }

  &:hover {
    background-color: $blue-color;
    box-shadow: $main-shadow;
  }

  &.checked {
    background-color: $blue-color;
    box-shadow: $main-shadow;
  }

  &:disabled {
    background-color: $disabled-buttons;
    color: $text-color-description;
    pointer-events: none;
    box-shadow: none;

    svg path:nth-of-type(1){
      stroke: $text-color-description !important;
    }
  }

  &.only-text {
    width: 140px;
    display: inline-block;
  }
}

@mixin label($color, $background) {
  display: inline-block;
  vertical-align: top;
  margin-left: 11px;
  padding: 3px;
  @include mainFont($size: 8px, $lh: 11px, $fw: 700);
  text-transform: uppercase;
  border-radius: 2px;
  color: $color;
  background-color: $background;
}

// checkbox
@mixin checkbox {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border-radius: 2px;
  margin: 10px;
  cursor: pointer;

  &.checked {
    background: url("../commonComponents/Checkbox/images/checked-dark.svg")
      center no-repeat $white;
  }
}

// padding
@mixin fieldPadding {
  padding: 10px 15px;
}

// focus
@mixin borderFocus {
  &:focus {
    outline: 2px solid $focus-color;
  }

  &:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }

  &:focus-visible {
    outline: 2px solid $focus-color;
    border-radius: 0;
  }
}

// cards style
@mixin cardContainer {
  position: relative;
  width: auto;
  height: auto;
  padding: $big-desktop-padding;
  background-color: $card-background;
  border-radius: 10px;

  @include big-desktop {
    padding: $tablet-padding;
  }
}

@mixin imageWrapper {
  @include flex($just: center, $ali: center);
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  margin-right: 10px;
  background-color: $resources-add-background;
  border-radius: 50%;
}

@mixin tip {
  @include mainFont($size: 10px, $lh: 14px, $color: $text-color-description);
  margin-left: 5px;
  padding: 3px 5px;
  background-color: $mini-button-color;
  border-radius: 5px;
}

@mixin mainContainer {
  margin-top: 56px;
  grid-area: content;
}

@mixin textArea {
    @include customScrollbar;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 15px 10px 15px;
    @include mainFont($size: 13px, $lh: 16px);
    background: rgba(42, 46, 70, 0.5);
    border: none;
    border-radius: 5px;

    &::placeholder {
      color: $white;
    }

    &:active,
    &:focus {
      outline: none;
    }
}
