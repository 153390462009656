@import "../../../../commonStyles/main.module.scss";

.modalContainer {
  position: fixed;
  inset: 0px;
  background-color: $modal-shadow;
  backdrop-filter: blur(6px);
  z-index: $zIndex-popup;

  &.hiddenModal {
    display: none;
  }

  .modalContent {
    position: absolute;
    inset: 50% auto auto 50%;
    width: fit-content;
    min-width: 388px;
    height: auto;
    padding: 20px;
    outline: none;
    background-color: $modal-content;
    border: transparent;
    border-radius: 10px;
    transform: translate(-50%, -50%);

    @include tabletMedium {
      width: 345px;
      box-sizing: border-box;
      padding: 15px;
    }

    & input {
      @include tabletMedium {
        margin-bottom: 0;
      }
    }

    & svg {
      @include tabletMedium {
        bottom: 10px;
      }
    }

    & .closeButton {
      position: absolute;
      border: none;
      top: 0;
      left: auto;
      right: -75px;
      background-color: transparent;
      cursor: pointer;

      @include tabletMedium {
        width: 30px;
        height: 30px;
        top: -40px;
        right: auto;
        left: 50%;
        padding: 0;
        transform: translateX(-50%);
      }

      & svg {
        @include tabletMedium {
          width: 30px;
          height: 30px;
        }
      }
    }

    .title {
      @include mainFont($size: 20px, $lh: 20px);

      @include tabletMedium {
        max-height: calc(100vh - 100px);
        overflow: auto;
        font-size: 16px;
      }
    }
  }
}

.activeModal {
  z-index: $zIndex-popup;
  pointer-events: all;
}

.activeContent {
  cursor: default;
}

.historyModal {
  padding: 0 !important;
  min-width: fit-content !important;
  background-color: unset !important;
}

.error {
  z-index: $zIndex-err-popup;
}
