@import "../../../commonStyles/main.module";

.spinnerContainer {
  @include flex($dir: column, $just: center, $ali: center);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: $all-overlay-z-index + 1;
  background-color: $main-background;

  span {
    @include mainFont($size: 16px);
    margin-top: 15px;
    clip-path: inset(0 1ch 0 0);
    animation: l 2s steps(4) infinite;
  }
}

.spinnerWrapper {
  margin-top: 15px;
  font-size: 20px;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.stopLoading {
  animation: remove 1s forwards;
  visibility: visible;
}

@keyframes remove{
  0% {opacity: 1; visibility: visible;}
  100% {opacity: 0; visibility: hidden;}
}
