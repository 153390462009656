@import "../../../commonStyles/main.module";

.connectExchangeContainer {
  @include flex($dir: column, $just: center, $ali: center);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: $card-background;
  z-index: $all-overlay-z-index;
  backdrop-filter: blur($overlay-blur);
  background-color: $modal-shadow;

  border-radius: 10px;

  .connectExchangeBody {
    @include flex($dir: column, $just: center, $ali: center);

    > div {
      height: 50px;
      position: relative;
    }

    .connectExchangeLabel {
      @include mainFont($size: 20px);
      position: relative;
      color: $text-color-description;
      width: 450px;
      height: fit-content;
      text-align: center;
      margin-bottom: 30px;

      .exchangeLabel {
        margin: 0 5px;
        font-size: 22px;
        color: $green-color;
      }
      @include mobile {
        font-size: 20px;
        width: 80%;
      }
    }

    button {
      height: 50px;
      width: 240px;
      font-size: 18px;
      background-color: $card-background;
      padding: 10px 18px;
      border-radius: 10px;
    }

    .logoutButton {
      height: 70px;
      width: 240px;
      margin: 20px 0;
    }
  }
}
