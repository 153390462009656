@import "../../../commonStyles/mixins.module";

.BalanceContainer {
  @include cardContainer;
  background-color: $blue-color;
  box-shadow: $main-shadow;
  height: 200px;
  min-width: 300px;
  box-sizing: border-box;

  & .title {
    @include mainFont($size: 20px, $lh: 20px);
    margin-bottom: 20px;
  }

  & .value {
    margin-top: 10px;
    margin-bottom: 15px;
    // @include mainFont($size: 30px, $lh: 28px);
    @include mainFont($size: 36px, $lh: 36px);
  }

  & .description {
    // @include mainFont($size: 18px, $lh: 18px);
    @include mainFont($size: 22px, $lh: 22px);
    color: $text-color-gray;
  }

  & > button {
    margin-top: 30px;
  }
}
