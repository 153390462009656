@import "../../../commonStyles/main.module.scss";

.LinearGraphContainer {
  background-color: $chart-background;
  border-radius: 5px;

  :global(.recharts-cartesian-grid-horizontal) > .grid:nth-last-child(2),
  :global(.recharts-cartesian-grid-horizontal) > .grid:nth-last-child(1) {
    display: none;
  }

  :global(.recharts-cartesian-axis-tick) {
    font-size: 10px;
    font-weight: 600;
    fill: $text-color-description;
  }
}

.tooltipBox {
  @include flex($dir: column, $ali: center);
  padding: 5px 10px;
  gap: 5px;
  background: $card-background;
  border-radius: 3px;
  box-shadow: none;

  & .usdAmount {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: $white;
  }

  & .date {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: $text-color-description;
  }
}

.chartWrapper {
  position: relative;

  .notEnoughDataLabel {
    @include mainFont($size: 14px, $lh: 16px);
    color: $text-color-description;
  }
}
