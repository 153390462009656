@import "../../../commonStyles/main.module";

.checkboxContainer {
  @include flex($ali: center);
  width: max-content;
  min-height: 30px;
  @include borderFocus;
  cursor: pointer;

  &.exchangeItem{
    width: 100%;
    max-width: 183px;
    height: 45px;
    border-radius: 5px;
    background-color: $mini-card-background;

    & .checked{
      background-color: $blue-color;
      border-radius: 5px;
    }

    & .item{
      @include flex($ali: center, $just: center);
      width: 100%;
      height: 100%;
    }
  }

  &.error {
    > div {
      border: solid 1px $error-color;
    }
  }
}
