@import "../../../../commonStyles/mixins.module";

.modalContent {
  @include flex;
  cursor: default;

  @include tabletMedium {
    flex-direction: column;
    max-height: calc(100h - 100px);
    width: 345px;
  }

  & .closeButton {
    position: absolute;
    border: none;
    top: 0;
    left: auto;
    right: -75px;
    background-color: transparent;
    cursor: pointer;

    @include tabletBig {
      top: -40px;
      left: 50%;
      right: auto;
      width: 30px;
      height: 30px;
      padding: 0;
      transform: translateX(-50%);
    }

    svg {
      @include tabletBig {
        width: 30px;
        height: 30px;
      }
    }
  }

  .header {
    @include flex($ali: center, $just: space-between);
  }

  .title {
    @include font_h2;
  }

  .HistoryContent {
    height: 100%;
  }

  .row {
    margin-top: 20px;
    height: 100%;

    @include mobile {
      margin-top: 5px;
    }
  }

  .subTitle {
    margin-bottom: 10px;
    @include mainFont($size: 13px, $lh: 16px);
    color: $text-color-description;
  }

  .list {
    margin: 0;
    padding: 0;
    list-style: none;
    @include mainFont($size: 14px, $lh: 16px);
  }

  .title {
    @include mainFont($size: 20px, $lh: 20px);

    @include tabletMedium {
      font-size: 16px;
    }
  }

  .aside {
    width: 260px;
    padding: 20px;
    background-color: $modal-menu;
    border-radius: 10px 0 0 10px;

    @include tabletMedium {
      width: 100%;
      margin-bottom: 15px;
      padding: 15px;
      box-sizing: border-box;
      border-radius: 10px;
    }

    @include mobile {
      margin-bottom: 10px;
      padding: 10px;
    }

    .menu {
      @include flex($dir: column);
      gap: 5px;
      margin-top: 20px;

      @include tabletMedium {
        margin-top: 10px;
        @include flex($dir: row, $just: space-between, $wrap: wrap);
      }

      button {
        width: 100%;
        padding: 10px;
        @include mainFont($size: 13px, $lh: 16px);
        text-align: left;
        background-color: $card-row-background;
        border-radius: 5px;
        border: none;
        cursor: pointer;

        @include tabletMedium {
          width: calc(50% - 105px);
          text-align: center;
          font-size: 10px;
        }

        &.active {
          background-color: $blue-color;
          pointer-events: none;
        }
      }
    }

    .submenu {
      margin-top: 15px;

      @include mobile {
        margin-top: 10px;
      }
    }
  }

  .main {
    max-width: 450px;
    width: 45vw;
    height: 450px;
    padding: 20px;
    border-radius: 0 10px 10px 0;
    background-color: $modal-content;

    @include large {
      width: 40vw;
    }

    .listContainer {
      overflow-x: auto;
      cursor: pointer;
      height: 370px;

      svg {
        path {
          fill: rgb(73, 77, 103);
        }
      }

      .loupe {
        text-align: center;
        margin-top: 15%;

        svg {
          width: 100px;
        }

        .label {
          @include mainFont($size: 18px, $lh: 24px);
          width: 170px;
          margin: 20px auto;
        }
      }

      @include tabletMedium {
        height: 270px;
      }
    }

    @include tabletMedium {
      width: 345px;
      padding: 15px;
      height: auto;
      box-sizing: border-box;
      border-radius: 10px;
    }

    @include mobile {
      padding: 10px;
    }
  }

}
