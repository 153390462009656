@import "../../../../commonStyles/_mixins.module";

.TickerContainer {
  overflow-x: hidden;
  position: relative;
  width: 100vw;

  @include desktop{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $main-background;
  }

  &:before,
  &:after{
    content: "";
    z-index: $fixed-el-z-index;
    position: absolute;
    height: 100%;
    width: 20%;
    top: 0;
  }

  &:before {
    left: 0;
    background: linear-gradient(90deg, #11121E 0%, rgba(17, 19, 31, 0) 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(270deg, #11121E 0%, rgba(17, 19, 31, 0) 100%);
  }

  :global(.rfm-child) {
    @include flex;
    margin-right: 20px;

    .imgContainer {
      @include imageWrapper;
      margin: 0;

      svg {
        width: 20px;
        path {
          fill: white;
        }
      }
    }

    & .content {
      @include flex($dir: column, $just: space-between, $ali: flex-start);
      margin-left: 10px;
      padding-top: 4px;
      padding-bottom: 4px;

      & .top {
        @include flex($ali: center);

        & .title {
          @include mainFont($size: 12px, $lh: 11px);
          white-space: nowrap;
        }
      }

      & .bottom {
        @include mainFont($size: 12px, $lh: 14px);
        color: $text-color-description;
        margin-top: 5px;
      }
    }
  }
}

.portfolioTicker {
  margin-left: 200px;

  @include desktop{
    margin-left: 0;
  }
}
