@import "../../../commonStyles/main.module";

.description {
  @include font_h3;
  text-align: center;
  margin: $main-margin 0;
  word-break: break-word;
  line-height: 20px
}

.steps {
  @include font_p_dark;

  li {
    list-style: none;
    margin-bottom: 14px;

    &::before {
      content: url("../../../commonComponents/ModalWindowController/ModalWindow/images/stepIcon.svg");
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: bottom;
      padding-right: 5px;
    }
  }
}
