@import "../../../commonStyles/main.module";

.NotificationModalContainer {
  @include mainFont($size: 14px);
  position: fixed;
  background-color: $green-color;
  color: $white;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 40px;
  animation: showTop 1s;
  z-index: $all-overlay-z-index + 2;
  @include flex($just: center, $ali: center);
  gap: 10px;

  @include mobile {
    font-size: 12px;
    width: 90%;
    top: 88px;
  }

  svg {
    path {
      stroke: white;
    }
  }
}

@keyframes showTop {

  from {top:-100%;}

  to {top: 40;}

  }



