@import "../../../commonStyles/main.module.scss";

.ResourcesInfoContainer {
  grid-area: $assets;
  overflow-x: auto;
  width: 100%;

  .PortfolioAssetsOverflow {
    @include mobile {
      overflow-x: scroll;
    }
  }

  @include cardContainer;
  .title {
    @include font_h2;
    margin-bottom: 20px;
  }

  .ResourcesInfoContent {
    .imageWrapper {
      @include imageWrapper;
      margin: 0;

      svg {
        width: 20px;
      }
    }

    .row {
      @include flex($ali: center);
      gap: 15px;
      padding: 10px;
      border-radius: 5px;
      cursor: auto;
      border: 1px solid $card-background;

      .imageWrapper {
        svg {
          path {
            fill: white;
          }
        }
      }

      div:nth-child(2) {
        flex-grow: 1;
      }

      &:nth-child(odd) {
        background-color: $resources-card-background;
      }
    }

    .activeRow {
      cursor: pointer;

      &:hover {
        border: 1px solid $blue-color;
      }

    }
    .gridRow {
      display: grid;
      grid-template-columns: 50px 2.2fr repeat(2, 2.2fr) 2fr 2.2fr;

      @include mobile {
        grid-template-columns: 50px 120px 120px 120px 100px 105px;
      }

      @include mobile {
        width: fit-content;
      }

      grid-template-rows: max-content;
      grid-auto-flow: row;
      grid-auto-rows: 36px;
      gap: unset;
      row-gap: 12px;

      div[class*="card"] {
        box-sizing: border-box;
        width: 100%;
        padding-top: 5px;
      }

      .name {
        margin-left: 15px;
      }

      .subItem {
        box-sizing: border-box;
        @include flex($ali: center);
        height: 100%;
        background-color: $resources-add-background;
        @include mainFont($size: 14px, $lh: 16px);

        &.imgWrapper {
          padding-left: 10px;
          grid-column: 1 / 3;
          border-radius: 5px 0 0 5px;
        }
      }
    }

    & .gridRowHeader {
      div[class*="card"] {
        padding-top: 0;
      }
      margin-bottom: 5px;
      border-radius: 0;
      background-color: #202234 !important;
    }
  }
}
