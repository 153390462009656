@import "../../../../commonStyles/main.module.scss";

.modalTitle {
  @include font_h2;
  margin-bottom: 22px;
}

.checkboxesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: 20px;

  .checkboxWide {
    @include font_h2($size: 16px);
    max-width: 100% !important;
    grid-column: 1 / 3;
    background-color: $mini-button-color;
  }

  .checkbox {
    height: 54px !important;
    background-color: $mini-button-color;
  }

  .updateExchange {
    position: absolute;
    visibility: hidden;
    top: -5px;
    right: 18px;
    width: 18px;
    height: 18px;
    background-color: $green-color;

    svg {
      width: 15px;
      path {
        stroke: $white;
      }

    }
  }

  .deleteExchange {
    position: absolute;
    visibility: hidden;
    top: -5px;
    right: -5px;
    width: 18px;
    height: 18px;
    background-color: $dark-red-color;

    svg {
      transform: rotate(45deg);
    }
  }

  .disabled {
    pointer-events: none;
  }

  .exchangeLabelMock {
    @include font_h2($size: 16px);
  }

  button {
    @include flex($ali: center, $just: center);
    height: 54px;
    background-color: $mini-button-color;

    svg {
      margin: 0;
    }

    button:hover svg {
      fill: white;
    }
  }

  .checkboxWrapper {
    position: relative;
  }

  .checkboxWrapper:hover > .deleteExchange {
    visibility: visible;
  }

  .checkboxWrapper:hover > .updateExchange {
    visibility: visible;
  }
}
