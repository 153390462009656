@import "../../../commonStyles/_mixins.module";

.card {
  @include flex($dir: column, $ali: flex-start);
  width: fit-content;
  padding: 10px;
  background-color: $mini-card-background;
  border-radius: 5px;

  &.transparent {
    padding: 0;
    background-color: transparent;

    & .description {
      white-space: normal;
    }
  }

  &.rightAlignment {
    align-items: flex-end;
  }

  & .title {
    margin-bottom: 5px;
    @include mainFont($size: 14px, $lh: 16px);
    white-space: nowrap;

    &.red {
      color: $red-text;
    }

    &.green {
      color: $green-color;
    }
  }

  & .description {
    @include mainFont($size: 12px, $lh: 14px);
    color: $text-color-description;
    white-space: nowrap;
  }
}
