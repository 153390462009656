@import "../../../commonStyles/main.module";

.AsideContainer {
  position: fixed;
  top: $big-desktop-padding;
  width: 220px;
  height: calc(100% - 40px);
  padding: 20px;
  background-color: $card-background;
  border-radius: 10px;
  box-sizing: border-box;

  .logo {
    @include mainFont($size: 24px, $lh: 24px);
    margin-bottom: 10px;
    margin-left: 5px;
    font-weight: 900;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    span {
      color: $blue-color;
      cursor: pointer;
    }

    @include desktop {
      display: none;
    }
  }

  @include big-desktop {
    top: $tablet-padding;
    height: calc(100% - 30px);
  }

  @include desktop {
    top: auto;
    left: 0;
    bottom: 0;
    @include flex($just: center);
    width: 100%;
    height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    z-index: $upper-modal-z-index;
    border-radius: 0;
  }

  @include mobile {
    padding: 15px;
  }

  & .linkContainer {
    @include flex($dir: column);

    @include desktop {
      @include flex($dir: row, $just: space-between);
      max-width: 762px;
      width: 100%;
      padding-left: 70px;
      padding-right: 70px;
      box-sizing: border-box;
    }

    @include tabletMedium {
      padding-left: 0;
      padding-right: 0;
    }

    button {
      @include tabletSmall {
        padding-left: 0;
        padding-right: 0;
      }

      @include mobile {
        font-size: 10px;
      }
    }
  }

  .supportContainer {
    @include mainFont($size: 13px, $lh: 16px);
    position: absolute;
    bottom: 20px;
    width: 180px;

    @include desktop {
      display: none;
    }

    :global(.language) {
      &__menu {
        &-list {
          @include customScrollbar;
          @include mainFont($size: 13px, $lh: 16px);
        }
      }
    }
  }
}
