@import "../../../../commonStyles/_mixins.module";

.indicator{
  @include flex($ali: center);
  margin-left: 5px;
  @include mainFont($size: 12px, $lh: 11px);
  color: $red-color;

  &.growing{
    color: $green-color;

    & svg{
      stroke: $green-color;
      transform: rotate(0);
    }
  }

  & svg{
    margin-right: 5px; 
    stroke: $red-color;
    transform: rotate(180deg);
  }
}