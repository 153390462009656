@import "src/commonStyles/main.module";

.ExchangeSelectContainer {
  @include desktop {
    width: calc(100% - 40px);
    position: absolute;
    border-radius: 5px;
    margin-top: -10px;
    height: 20px;

    button {
      height: 30px;
    }
  }

  & > svg {
    display: none;
    margin-bottom: 10px;

    @include desktop {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .selectButton {
    @include mainFont($size: 13px, $lh: 16px);
    @include flex($ali: center, $just: space-between);
    flex: 1 0 auto;
    width: 19%;
    height: 42px;
    margin-right: 20px;
    padding: 12px 10px;
    background-color: $card-background;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    position: absolute;
    z-index: 99;

    &.noSpace {
      justify-content: flex-start;
    }

    @include desktop {
      width: 180px;
      height: 30px;
      margin-top: 10px;
      margin-left: 24px;
      background-color: $mini-card-background;
    }

    @include tabletMedium {
      width: 140px;
      margin-left: 16px;
    }
  }

  @include mobile {
    .selectButton {
      width: 95px;
      font-size: 10px;
      margin-left: -7px;

      svg {
        height: 10px;
      }
    }
  }

  .ModalContent {
    margin-top: 20px;

    @include tabletMedium {
      max-height: calc(100vh - 100px);
      overflow: auto;
      margin-top: 10px;
    }

    & .title {
      @include mainFont(
        $size: 13px,
        $lh: 16px,
        $color: $text-color-description
      );
    }

    & .exchanges {
      @include flex($wrap: wrap, $just: space-between);
      margin-top: 20px;

      @include tabletMedium {
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
        margin-bottom: -10px;
      }

      & > div {
        margin-bottom: 20px;

        @include tabletMedium {
          margin-bottom: 10px;
          max-width: 153px;
        }
      }
    }
  }
}

.ExchangeSelectContainerInsideCard {
  width: 240px;

  .selectButton {
    margin: 0;
    width: 240px;
    background-color: $black-select-background;
  }
}
