@import "../../../commonStyles/mixins.module";

.LoginPageContainer {
  max-width: 1400px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;

  & .LoginPageContent {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 20px;
    background-color: $card-background;
    border-radius: 10px;

    @include mobile {
      width: 85%;
    }

    .ressetPasswordLogo {
      @include mainFont($size: 20px);
      text-align: center;
      margin-bottom: 10px;
    }

    .errorLabel {
      @include mainFont($size: 14px);
      color: $red-text;
    }

    .ResetPageButtons {
      margin-top: 10px;
    }
  }
}
