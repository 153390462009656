@import "../../../commonStyles/main.module";

.SupportModalContainer {
  position: relative;
  .title {
    @include mainFont($size: 20px);
    margin-bottom: 10px;
  }

  textarea {
    width: 92%;
    height: 100px;
  }
}
