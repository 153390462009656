@import "../../../commonStyles/main.module.scss";

.StrategyParamsContainer {
  @include cardContainer;

  .title {
    @include font_h2;
    margin-bottom: 20px;

    @include large {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  .buttons {
    @include flex($ali: flex-start, $wrap: wrap);
    gap: 10px;

    button {
      width: 100%;
      flex-grow: 1;
      width: max-content;
      justify-content: center;
      padding: 10px 5px;
    }
  }
}

@include large {
  .StrategyParamsContainer {
    .title {
      font-size: 16px;
      margin-bottom: 12px;
    }

    .controls {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-columns: 1fr;
      grid-template-rows: repeat(4, auto);
      grid-auto-flow: column;
      column-gap: 10px;
    }

    .buttons {
      @include flex($ali: flex-start, $wrap: wrap);
      gap: 10px;

      button {
        max-width: 150px;
      }
    }
  }
}

@include tabletSmall {
  .StrategyParamsContainer {
    .controls {
      grid-template-rows: auto;
      grid-auto-flow: row;
    }
  }
}

@include mobile {
  .StrategyParamsContainer {
    .buttons {
      flex-wrap: wrap;

      button {
        max-width: unset;
      }
    }
  }
}
