@import "../../../commonStyles/main.module.scss";

.addCard {
  position: relative;
  height: 100%;
  min-height: 64px;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  background-color: $card-background;
  opacity: 0.5;
  cursor: pointer;

  @include tabletSmall {
    grid-column: 1 / 3;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 30px;
    height: 2px;
    background-color: $text-color-description;
    transform: translate(-50%, -50%);
  }

  &:after {
    margin-left: -15px;
    transform: rotate(90deg);
  }
}
