@import "../../../commonStyles/main.module";

.calendarContainer {
  .datesSelectedBlock {
    position: relative;
    width: 100%;
    @include flex($just: space-between, $ali: center);
    gap: 5px;

    .inputWrapper {
      box-sizing: border-box;
      flex: 1 0;
      padding: 10px;
      background-color: $card-row-background;
      border-radius: 5px;
      height: 38px;

      input {
        @include mainFont($fw: 600, $size: 13px, $color: $white);
        width: 100%;
        height: 18px;
        min-height: 18px;
        border: none;
        background-color: transparent;
        box-sizing: border-box;

        &::placeholder {
          @include mainFont($fw: 600, $size: 13px, $lh: 16px, $color: $white);
        }

        &::-webkit-calendar-picker-indicator {
          display: none;
        }

        &:focus,
        &:active {
          outline: none;
        }
      }
    }

    .calendarInitiator {
      height: 38px;
    }

    button {
      @include flex($just: center, $dir: center);
      box-sizing: border-box;
      background-color: $card-row-background;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      padding: 13px 0;
      width: 36px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .datePicker {
    position: absolute;
    z-index: $upper-modal-z-index;

    .calendar {
      @media (min-width: 320px) and (max-width: 480px) {
        @include flex($dir: column, $wrap: wrap, $just: center, $ali: center);
        width: 100%;
      }
    }
  }
}
